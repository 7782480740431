const th = (typeof(window) !== 'undefined' ? window : {});

const base_i = th.htmlBasePath;
const base_p = th.htmlBasePath + '/p';
const base_e = th.htmlBasePath + '/e';

export default {
    INITIAL: base_i + '/',
    PAGE_404: base_i + '/404',
    PAGE_403: base_i + '/403',
    PAGE_403_PUBLIC: base_p + '/403',
    PAGE_500: base_i + '/500',
    USER_PREFERENCES: base_i + '/user-preferences',
    UPLOAD_HISTORY: base_i + '/upload-history',
    SUBSCRIPTIONS: base_i + '/subscriptions',
    EXPIRATION_DASHBOARD: base_i + '/expiration-dashboard',
    PLAYLISTS: base_i + '/playlists',
    PLAYLISTS_MY: base_i + '/playlists/my',
    PLAYLISTS_ALL: base_i + '/playlists/all',
    PLAYLISTS_EXPIRED: base_i + '/playlists/expired',
    CUSTOMER_VIDEOS_WORKSPACE: base_i + '/customer-videos-workspace',

    //Admin Tool
    ADMIN_TOOLS: base_i + '/admin',
    ADMIN_OWNERSHIP: base_i + '/admin/ownership',
    ADMIN_DASHBOARD: base_i + '/admin/dashboard',
    SERVICE_API: base_i + '/admin/service_api',
    ADMIN_SEMAPHORE_CHANGES: base_i + '/admin/semaphore-changes',
    ADMIN_SYSTEM_ACTIONS: base_i + '/admin/system-actions',
    ADMIN_DB_CONFIG: base_i + '/admin/db-config',
    ADMIN_FEATURES_FLAGS: base_i + '/admin/features-flags',
    ADMIN_CONTENT_STORE_MANAGEMENT: base_i + '/admin/content-store-management',
    API_WORKSPACE_MANAGE: base_i + '/api-workspace-manage',
    CONFIG_SERVICE: base_i + '/admin/config-service',
    ADMIN_FEEDBACK_RESULTS: base_i + '/admin/feedback-results',

    //Lumira reports
    REPORTING_DASHBOARD_OLD: base_i + '/cabinet/reporting',
    REPORTING_DASHBOARD: base_i + '/reporting',
    DOWNLOAD_STATISTIC_LUMIRA: base_i + '/reporting/download-statistics',
    MMCC_REPORT_LUMIRA: base_i + '/reporting/mmcc',
    GBCC_REPORT_LUMIRA: base_i + '/reporting/gbcc',
    USER_STATISTIC_REPORT_LUMIRA: base_i + '/reporting/users-statistic',
    OUTREACH_REPORT_LUMIRA: base_i + '/reporting/outreach',
    SAP_GLOBAL_SPONSORSHIP_REPORT: base_i + '/reporting/sap-global-sponsorship',
    CONTENT_USAGE_REPORT_LUMIRA: base_i + '/reporting/content-usage',
    SAP_BUSINESS_TECHNOLOGY_PLATFORM_REPORT: base_i + '/reporting/sap_business_technology_platform',

    EXPORTED_XLS: base_i + '/exported-xls',
    LEARNING_CENTER: base_i + '/learning-center',
    EVENTS: base_i + '/events',
    SALES_KITS: base_i + '/sales-kits',
    DASHBOARD: base_i + '/dashboard',
    CURATORS_DASHBOARD: base_i + '/curators_dashboard',
    ASSET_PREVIEW: base_i + '/asset/preview',
    SSO_USER_ROOT: base_i + '/sso-user',
    SSO_USER: base_i + '/sso-user/home',
    PARTNER_FOLDER_PREVIEW: base_i + '/partner/folder',
    ARCHIVING: base_i + '/archiving',
    PROJECTS_WORKSPACE: base_i + '/projects-workspace',
    AUTHORIZED_GROUPS_MANAGEMENT: base_i + '/admin/authorized-members',

    CREATE_CMPS_PUBLISHING_FORM: base_i + '/create-cmps-publishing-form',
    EDIT_CMPS_PUBLISHING_FORM: base_i + '/edit-cmps-publishing-form',
    CLONE_CMPS_PUBLISHING_FORM: base_i + '/clone-cmps-publishing-form',
    CUSTOMER_VIDEO_CONTENT: base_i + '/customer-video-content',

    FEATURED_ASSETS: base_i + '/featured',
    FEATURED_ASSETS_SEARCH: base_i + '/featured/search',
    TRENDING_ASSETS: base_i + '/trending',
    RECENTLY_ADDED: base_i + '/recent',
    REUSED_ASSETS: base_i + '/reused-assets',
    ASSET_EXPIRED: base_p + '/assetExpired',
    MY_ASSETS: base_i + '/my-assets',
    MY_PROJECTS: base_i + '/my-assets/my-projects',
    NEW_SEARCH: base_i + '/search',
    SAVED_SEARCH: base_i + '/search/saved',
    CONTENT_STORE: base_i + '/cs/',
    OUTREACH_SEARCH: base_e + '/search/outreach',
    CS_SEARCH: base_i + '/search/cs',
    /* TODO CS REMOVE all direct links*/

    FOLDER: base_i + '/folder',
    FOLDER_ASSETS: base_i + '/assets',
    CS: base_i + '/cs',
    CSC: base_i + '/csc',
    NEW_CONTENT_STORES: base_i + '/csc/Marketing',
    NEW_CONTENT_STORES_SALES: base_i + '/csc/Sales',
    NEW_CONTENT_STORES_POST_SALES: base_i + '/csc/Post Sales',
    NEW_CONTENT_STORES_ADD_VALUE: base_i + '/csc/Customer Advisory',
    NEW_CONTENT_STORES_PRIVACY_AND_PROTECTION: base_i + '/csc/Privacy & Protection',
    NEW_CONTENT_STORES_DATA_AND_ANALYTICS: base_i + '/csc/Data & Analytics',
    NEW_CONTENT_STORES_CONTENT_GOVERNANCE: base_i + '/csc/Content Governance',
    NEW_CONTENT_STORES_LEARNING_CENTER: base_i + '/csc/Learning Center',
    AGENCIES: base_i + '/agencies',
    CONTENT_STORES_CURATED: base_i + '/cs/curated',
    CONTENT_STORES_FOR_MARKETING_TASKS: base_i + '/cs/gco',
    CONTENT_STORES_FOR_LEARNING: base_i + '/cs/saplearning',
    CONTENT_STORES_FOR_EVENTS: base_i + '/cs/event',
    CONTENT_STORES_FOR_COMMERCIAL: base_i + '/cs/genbusiness',
    CONTENT_STORES_FOR_RENEWAL_OPS: base_i + '/cs/renewalops',
    CONTENT_STORES_FOR_EXECUTIVE: base_i + '/cs/executive',
    CONTENT_STORES_FOR_LASER: base_i + '/cs/old/campaign',
    PROGRAMS_AND_CAMPAIGNS: base_i + '/cs/programs',
    CONTENT_STORES_FOR_JAM: base_i + '/cs/jam',
    CONTENT_STORES_FOR_BRANDING: base_i + '/cs/branding',
    CONTENT_STORES_FOR_INDUSTRY: base_i + '/cs/industry',
    CONTENT_STORES_FOR_TASK: base_i + '/cs/task',
    CONTENT_STORES_FOR_SALES_KITS: base_i + '/cs/saleskits',
    CONTENT_STORES_FOR_CVP: base_i + '/cs/cvp',
    BOM_CAMPAIGNS: base_i + '/cs/campaign',
    CONTENT_STORES_MISCELLANEOUS: base_i + '/cs/miscellaneous',
    CONTENT_STORES_DEMAND_CENTER: base_i + '/cs/demand',
    CONTENT_STORES_MARKET_UNIT: base_i + '/cs/market',
    CONTENT_STORES_PARTNER_IMPACT: base_i + '/cs/partner_impact',
    CONTENT_STORES_SERVICES: base_i + '/cs/services',
    CONTENT_STORES_INDUSTRY_NEW: base_i + '/cs/industry_new',
    CONTENT_STORES_ARIBA_AND_FIELDGLASS: base_i + '/cs/ariba_and_fieldglass',
    CONTENT_STORES_VA_ARCH_DIAG: base_i + '/cs/va_arch_diag',
    CONTENT_STORES_VA_ASSESSEMENT: base_i + '/cs/va_assessment',
    CONTENT_STORES_VA_BUSINES_CASES: base_i + '/cs/va_busines_cases',
    CONTENT_STORES_VA_CUSTOMER_STORIES: base_i + '/cs/va_customer_stories',
    CONTENT_STORES_VA_DEMO_SCRIPTS: base_i + '/cs/va_demo_scripts',
    CONTENT_STORES_VA_EXECUTIVE_DECKS: base_i + '/cs/va_executive_decks',
    CONTENT_STORES_VA_INDUSTRY_MAPS: base_i + '/cs/va_industry_maps',
    CONTENT_STORES_VA_JOURNEY_MAPS: base_i + '/cs/va_journey_maps',
    CONTENT_STORES_VA_ROADMAPS: base_i + '/cs/va_roadmaps',
    CONTENT_STORES_VA_POINT_OF_VIEW: base_i + '/cs/va_point_of_view',
    CONTENT_STORES_CONTENT_USE_PERMISSIONS: base_i + '/cs/content_use_permissions',
    CONTENT_STORES_ABOUT: base_i + '/cs/about',
    CONTENT_STORES_CUSTOMER_STORY: base_i + '/cs/customer_story',
    CS_REPORTING: base_i + '/cs-reporting',

    WORKSPACE: base_i + '/space'
};
